import React from 'react';
import './MyEpgCalendarContainer.css';

const MyEpgCalendarContainer = (props) => {
    const activeChannel = props.activeChannel
    const epgDay = props.epgDay
    const months = ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი']
    const days = ['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი']
    return (
        <ul id="EpgCalendar" className="EpgCalendar">
            { Array(7).fill(1).map((el, i) => {
                const newFullDate = new Date(Date.now() - parseInt(6-i)*24*60*60*1000);
                const newDay = days[newFullDate.getDay()]
                const newDate = newFullDate.getDate()
                const newMonth = months[newFullDate.getMonth()]
                return (
                    <li onClick={() => props.getEpgData(activeChannel, newFullDate, i)} className={(epgDay === i) ? "epg-calendar-item-active" : "epg-calendar-item"}>
                        <div className='epg-calendar-parent'>
                            <div className='date-block'>{newDate}</div>
                            <div className='date-text'>
                                <div className='month'>{newMonth}</div>
                                <div className='day'>{newDay}</div>
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    );
};

export default MyEpgCalendarContainer;