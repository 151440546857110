import React from 'react';
import MyTv from './MyTv';

function App() {
  return (
    <div className="App">
      <MyTv />
    </div>
  );
}

export default App;