import React,{ useRef, useState, useEffect } from 'react';
// import { faBackward, faForward, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter, Link, Switch } from 'react-router-dom';
import MyMainMenu from './UI/MyMainMenu';
import "./styles/App.css"
// import MyButton from './UI/MyButton';
// import myBtnCss from './UI/MyButton.module.css';
import ReactPlayer from 'react-player';
import axios from "axios";
// import MyChannelsList from './UI/MyChannelsList';
// import MyEpgList from './UI/MyEpgList';
// import { scroller } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import showWhenBuffer from "./img/load-1.gif"

// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import Slider from '@mui/material/Slider';
// import VolumeDown from '@mui/icons-material/VolumeDown';
// import VolumeUp from '@mui/icons-material/VolumeUp';
// import { display, style, width } from '@mui/system';
// import AppRouter from './UI/AppRouter';
// import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
// import MaterialIcon from 'react-google-material-icons';
//import MaterialIconO from 'react-google-material-icons-outlined';
// import { CloseOutlined } from '@mui/icons-material';
import MyPlayerInnerControls from './UI/MyPlayerInnerControls';
import MyRullerDrawLines from './UI/MyRullerDrawLines';
import { useCookies } from 'react-cookie'
import MyEpgDotContainer from './UI/MyEpgDotContainer';
import MyPlayerControls from './UI/MyPlayerControls';
// import MyRecordLine from './UI/MyRecordLine';
import MyEpgListContainer from './UI/MyEpgListContainer';
import MyChannelsListContainer from './UI/MyChannelsListContainer';
import MyEpgCalendarContainer from './UI/MyEpgCalendarContainer';
import MyStreamLineContainer from './UI/MyStreamLineContainer';

let startTimeStamp = Math.floor(Date.now()/1000);
let videoTimeStamp = startTimeStamp;
var myTimer = 0;
var _epgFullDate = startTimeStamp;
var elapsedSeconds = 0;

function MyTv() {

  const [cookies, setCookie] = useCookies(['name']);
  const months = ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი']
  const days = ['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი']

  const myPlayerRef = useRef()

  // const apiUrl = "https://api.mediabox.ge/api/WebApi.php"
  // const apiUrl = window.location.hostname + "/webapi";
  const apiUrl = "/webapi";
  // const apiKey = "3DA33AC5E46D14C76FF72EE421ABA"
  const apiKey = "1234";

  const [url, setUrl] = useState("")
  const [channels, setChannels] = useState([])
  const [channelEPG, setChannelEPG] = useState([])
  const [liveChannelData, setLiveChannelData] = useState([])
  const [recordedChannelData, setRecordedChannelData] = useState([])
  const [recordingRanges, setRecordingRanges] = useState([])
  const [epgDay, setEpgDay] = useState(6)
  const [epgFullDate, setEpgFullDate] = useState()

  const [activeChannel, setActiveChannel] = useState();
  const [isBuffering, setIsBuffering] = useState(false);
  const [isLive, setIsLive] = useState(true);

  const [playing, setPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [muted, setMuted] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isShowControls, setIsShowControls] = useState(false)
  const [hoverSeconds, setHoverSeconds] = useState(0)
  const [clientWidth, setClientWidth] = useState()

  const ApiCall = async (props) => {
    const request = props
    request['apikey'] = apiKey
    const obj = JSON.stringify(request)
    const response = await axios.post(apiUrl, obj)
    return response.data
  }

  async function GetChannelsList() {
    const channelsList = await ApiCall({'Method':'GetChannelList'})
    setChannels(channelsList)
    const chFromUrl = window.location.pathname.split("/tv/")[1];
    var setChannel = 0;
    if (cookies.activeChannel > 0) setChannel = cookies.activeChannel
    if (setChannel === 0 && chFromUrl && chFromUrl > 0) setChannel = chFromUrl
    if (setChannel === 0) setChannel = channelsList[0]['UID']
    setActiveChannel(setChannel);
    if (cookies.volume) setVolume(cookies.volume);
  }

  const getRecordingStatus = async () => {
    const recChannelData = await GetArchiveStream()
    if (recChannelData.URL) {
      const startStop = getDayStartStop(_epgFullDate)
      const url = recChannelData.SERVER + recChannelData.CHANNEL + '/recording_status.json?from=' + startStop[0] + '&to=' + startStop[1] + '&request=ranges'
      const recRanges = await axios.get(url)
      if (recRanges.data[0]['ranges']) {
        setRecordingRanges(recRanges.data[0]['ranges'])
      }
    } else {
      setRecordingRanges([])
    }
  }

  async function GetLiveStream(CH_ID = activeChannel) {
    const channelData = await ApiCall({'Method':'GetLiveStream', 'Pars':{"CHANNEL_ID":CH_ID}, 'urltype':'flussonic'})
    setLiveChannelData(channelData)
    return channelData
  }

  async function GetArchiveStream(CH_ID = activeChannel) {
    const channelData = await ApiCall({'Method':'GetArchiveStream', 'Pars':{"CHANNEL_ID":CH_ID}, 'urltype':'flussonic'})
    setRecordedChannelData(channelData)
    return channelData
  }

  const getDayStartStop = (tms) => {
    var jstms = new Date(tms*1000);
    jstms.setHours(0,0,0,0)
    var Start = Math.floor(jstms/1000)
    const DaySize = 86400 //60*60*24
    const StartStop = [Start, Start+DaySize-1]
    return StartStop
  }

  async function getEpgData(CH_ID = activeChannel, actDate = new Date(), i = -1) {
    setEpgFullDate(Math.floor(actDate/1000));
    _epgFullDate = Math.floor(actDate/1000);
    var startStop = getDayStartStop(actDate/1000);
    var start = startStop[0]
    var stop = startStop[1]
    const channelData = await ApiCall({'Method':'LoadEpgData', 'Pars':{"CHANNEL_ID": CH_ID, "TIME_START": start, "TIME_END": stop}})
    setChannelEPG(channelData);
    if ( i >= 0 ) setEpgDay(i);
    document.getElementsByClassName("streamLineCurrentTime")[0].style.display = 'none';
    document.getElementsByClassName("streamLineCurrentTimeArrow")[0].style.display = 'none';
    getRecordingStatus()
  }

  function Play() {
    setPlaying(true)
  }

  function Stop() {
    setPlaying(false)
  }

  const PlayPause = () => {
    setPlaying(!playing)
  }

  const playLive = async (CH_ID = activeChannel) => {
    if (CH_ID) {
      setIsLive(true);
      setEpgDay(6);
      const channelData = await GetLiveStream(CH_ID)
      Stop()
      startTimeStamp = Math.floor(Date.now()/1000);
      videoTimeStamp = startTimeStamp
      setActiveChannel(CH_ID)
      setUrl(channelData['URL'])
      Play()
      getEpgData(activeChannel);
    }
  }

  const playRewind = async (offset) => {
    setIsLive(false);
    Stop()
    const channelData = await GetArchiveStream(activeChannel)
    if (channelData && channelData['URL']) {
      startTimeStamp = videoTimeStamp + offset -5
      videoTimeStamp = startTimeStamp
      if (videoTimeStamp >= Math.floor(Date.now()/1000)) {
        playLive();
      } else {
        const nURL = (channelData['URL'].split('index.m3u8'))[0] + "video-timeshift_abs-" + (startTimeStamp) + ".m3u8?token=" + (channelData['URL'].split('?token='))[1]
        setUrl(nURL)
        Play()
      }
    }
  }

  const playRewindAbs = async (absTimeStamp) => {
    setIsLive(false);
    Stop()
    const channelData = await GetArchiveStream(activeChannel)
    if (channelData && channelData['URL']) {
      startTimeStamp = absTimeStamp
      videoTimeStamp = startTimeStamp
      if (videoTimeStamp >= Math.floor(Date.now()/1000)) {
        playLive();
      } else {
        const nURL = (channelData['URL'].split('index.m3u8'))[0] + "video-timeshift_abs-" + (startTimeStamp) + ".m3u8?token=" + (channelData['URL'].split('?token='))[1]
        setUrl(nURL)
        Play()
      }
    }
  }

  const playRewindLine = (element) => {
    const newFullDate = new Date(Date.now() - parseInt(6-epgDay)*86400*1000);
    var start = newFullDate.setHours(0,0,0,0)/1000 + hoverSeconds
    playRewindAbs(start)
  }

  const playerUpdateBufferring = (process) => {
    if (process === 'onBufferEnd') {
      setIsBuffering(false)
    } else {
      setIsBuffering(true)
    }
  }

  const onProgress = () => {
    if (playing) videoTimeStamp = parseInt(myPlayerRef.current.getCurrentTime()) + parseInt(startTimeStamp)
    const date = new Date(videoTimeStamp*1000);
    const gDate = days[date.getDay()] + ", " + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() + ", " + 
      date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
    document.getElementById("playerTime").innerText = gDate
    const videoPos = trackerPos(videoTimeStamp).toString() + '%';
    const lineCurrentTime = document.getElementsByClassName("streamLineCurrentTime")[0]
    const lineCurrentTimeArrow = document.getElementsByClassName("streamLineCurrentTimeArrow")[0]
    var startStop = getDayStartStop(_epgFullDate);
    var start = startStop[0]
    var stop = startStop[1]
    if ( videoTimeStamp >= start && videoTimeStamp < stop ) {
      lineCurrentTime.style.left = videoPos;
      lineCurrentTime.innerText = date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2);
      lineCurrentTime.style.display = 'block';
      lineCurrentTimeArrow.style.left = videoPos;
      lineCurrentTimeArrow.style.display = 'block';
    } else {
      lineCurrentTime.style.display = 'none';
      lineCurrentTimeArrow.style.display = 'none';
    }
  }

  const updateElapsedTimeBar = () => {
    var startStop = getDayStartStop(_epgFullDate);
    var start = startStop[0]
    var stop = startStop[1]
    const tmnow = Math.floor(Date.now()/1000);
    var nowPos = '100%';
    if ( tmnow >= start && tmnow < stop ) {
      nowPos = trackerPos(Date.now()/1000).toString() + '%';
    }
    const c = document.getElementById("elapsedTimeBar")
    if (c) {
      c.style.width = nowPos
    }
  }

  const handleResize = () => {
    var col1w, col2w, col3w, col4w;
    // console.log('resized dimension:', window.innerWidth);
    setClientWidth(window.innerWidth);
    const ww = window.innerWidth;
    col2w = ww > 1920-80 ? 250-(1920-ww) : 170;
    col4w = (ww < 1920-80 && ww > 1920-200) ? 340-(1920-ww)+80 : 340
    col1w = (ww < 1920-200 && ww > 1920-300) ? 120-(1920-ww)+200 : 70
    if (ww <= 600) {
      col1w = '0px';
      // document.getElementById('mycol1').style.display = 'none';
      col2w = '50px';
      col3w = (ww-80).toString() + 'px';
      col4w = '0px';
      // document.getElementById('mycol4').style.display = 'none';
    } else if (ww <= 1000) {
      col1w = '50px';
      // document.getElementById('mycol1').style.display = 'block';
      col2w = '50px';
      col3w = (ww-130).toString() + 'px';
      col4w = '0px';
      // document.getElementById('mycol4').style.display = 'block';
    } else if (ww <= 1200) {
      col1w = '50px';
      // document.getElementById('mycol1').style.display = 'block';
      col2w = '200px';
      col3w = (ww-450).toString() + 'px';
      col4w = '160px';
      // document.getElementById('mycol4').style.display = 'block';
    } else if (ww <= 1600) {
      col1w = '110px';
      // document.getElementById('mycol1').style.display = 'block';
      col2w = '220px';
      col3w = (ww-600).toString() + 'px';
      col4w = '235px';
      // document.getElementById('mycol4').style.display = 'block';
    } else {
      col1w = '120px';
      // document.getElementById('mycol1').style.display = 'block';
      col2w = '250px';
      col3w = (ww-745).toString() + 'px';
      col4w = '340px';
      // document.getElementById('mycol4').style.display = 'block';
    }
    console.log('window=', ww, '    col1w=', col1w, '    col2w=', col2w, '    col3w=', col3w, '    col4w=', col4w);
    document.getElementById('mycol1').style.width=col1w;
    document.getElementById('mycol2').style.width=col2w;
    document.getElementById('mycol3').style.width=col3w;
    document.getElementById('mycol4').style.width=col4w;
  }

  useEffect(() => {
    const _delay = setInterval(delayCheck, 1000);
    GetChannelsList();
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      clearInterval(_delay);
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(() => {
    playLive(activeChannel);
    setCookie('activeChannel', activeChannel, { maxAge : 14400 })
    setPlaying(true)
  }, [activeChannel])

  useEffect(() => {
  }, [screenfull.isFullscreen]);

  useEffect(() => {
    setCookie('volume', volume, { maxAge : 14400 })
  }, [volume])

  const onClickFullScreen = () => {
    screenfull.request(myPlayerRef.current.wrapper)
    setIsFullScreen(true);
  }

  const trackerPos = (tms) => {
    var startStop = getDayStartStop(_epgFullDate);
    var start = startStop[0]
    const fullSize= 86400; //60*60*24
    const offset = tms - start;
    const position = 100*offset/fullSize;
    return position
  }

  const delayCheck = () => {
    elapsedSeconds = elapsedSeconds + 1;
    updateElapsedTimeBar()
    if (myTimer === 5) {
      setIsShowControls(false)
    }
    myTimer = myTimer + 1
    if (elapsedSeconds % 300) {
      // refresh epg
    }
  }

  function onPlayerOver() {
    setIsShowControls(true);
    myTimer = 0;
  }

  const onStreamLineHover = e => {
    const ev = e.nativeEvent
    var MyOffsetX = ev.offsetX === undefined ? ev.layerX : ev.offsetX
    var obj = document.getElementById("streamLine");
    var MyLen = obj.clientWidth;
    var timePos = Math.floor(MyOffsetX/(MyLen/86400)) // 60*60*24 = 86400
    setHoverSeconds(timePos)
    var timeStr = ("0" + Math.floor(timePos / 3600)).slice(-2) + ":" + ("0" + Math.floor((timePos % 3600)/60)).slice(-2) + ":" + ("0" + (timePos % 60)).slice(-2);
    var lineHoverTime = document.getElementsByClassName("streamLineHoverTime")[0]
    lineHoverTime.style.left = (timePos*100/86400).toString() + '%';
    lineHoverTime.innerText = timeStr
    lineHoverTime.style.display = 'block';
    var lineHoverTimeArrow = document.getElementsByClassName("streamLineHoverTimeArrow")[0];
    lineHoverTimeArrow.style.left = (timePos*100/86400).toString() + '%';
    lineHoverTimeArrow.style.display = 'block'
  }

  const streamHoverTimeHide = () => {
    var lineHoverTime = document.getElementsByClassName("streamLineHoverTime")[0]
    lineHoverTime.style.display = 'none';
    var lineHoverTimeArrow = document.getElementsByClassName("streamLineHoverTimeArrow")[0]
    lineHoverTimeArrow.style.display = 'none';
  }

  return (
    <div className='container-fluid'>
        <div className="row">
            <div className="playerTms">გადაცემის დრო: <span id="playerTime"></span></div>
        </div>
        <div className="myrow-2">
          <BrowserRouter>
            {/* <Switch>
                <AppRouter activeChannel={activeChannel}/>
            </Switch> */}
            <div id='mycol1' className="mycol-1 mainMenuCol">
                <div className="row">
                  <MyMainMenu hideNames={ window.innerWidth > 1200 ? false : true } />
                </div>
            </div>
            <div id='mycol2' className="mycol-2 channelListCol">
                <MyChannelsListContainer  hideNames={ window.innerWidth > 1000 ? false : true } channels={channels} activeChannel={activeChannel} setActiveChannel={setActiveChannel} />
            </div>
            <div id='mycol3' className="mycol-3 playerCol">
                <div className="row position-relative">
                    <div className="player__wrapper col" onMouseMoveCapture={onPlayerOver} id="myPlayerFF">
                        <ReactPlayer
                            ref={myPlayerRef}
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={url}
                            //pip={pip}
                            playing={playing}
                            //controls={true}
                            //light={light}
                            //loop={loop}
                            //playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onReady={() => playerUpdateBufferring('onReady')}
                            onStart={() => playerUpdateBufferring('onStart')}
                            onPlay={() => playerUpdateBufferring('onPlay')}
                            //onEnablePIP={this.handleEnablePIP}
                            //onDisablePIP={this.handleDisablePIP}
                            onPause={() => playerUpdateBufferring('onPause')}
                            onBuffer={() => playerUpdateBufferring('onBuffer')}
                            onBufferEnd={() => playerUpdateBufferring('onBufferEnd')}
                            onSeek={e => playerUpdateBufferring('onSeek', e)}
                            onEnded={() => playerUpdateBufferring('Ended')}
                            onError={e => playerUpdateBufferring('onError', e)}
                            onProgress={onProgress}
                            //onDuration={this.handleDuration}
                            //playIcon={<button></button>}
                            //light="https://i.stack.imgur.com/zw9Iz.png"
                        />
                        { (isShowControls) &&
                            <MyPlayerInnerControls 
                                volume={volume} 
                                setVolume={setVolume} 
                                muted={muted} 
                                setMuted={setMuted} 
                                myPlayerRef={myPlayerRef} 
                                myPlayerF={document.getElementById('myPlayerFF')}
                            />
                        }
                    </div>
                    <div id="imgBuffering" className="showWhenBuffer col position-absolute">
                        { isBuffering && <img src={showWhenBuffer} alt="Buffering ..."/> }
                    </div>
                </div>
            </div>
            <div id='mycol4' className="mycol-4 epgListCol">
                <MyEpgListContainer 
                    channelEPG={channelEPG} 
                    activeChannel={activeChannel} 
                    videoTimeStamp={videoTimeStamp} 
                    playRewindAbs={playRewindAbs} 
                />
            </div>
          </BrowserRouter>
        </div>
        <div className="row" style={{height:10}}></div>
        <div className="row" >
            <div className="bottom__controls">
                <MyPlayerControls 
                    playRewind={playRewind} 
                    PlayPause={PlayPause} 
                    playLive={playLive} 
                    isLive={isLive} 
                    playing={playing} 
                />
                <div className="timeline__controls">
                    <div className="ruller">
                        { Array(25).fill(1).map((el, i) => {
                            var xoffs = (i*100/24).toString() + '%';
                            var HM = (i === 24) ? "23:59" : ("0" + i.toString() + ":" + "00").slice(-5)
                            return (
                              <div className="hoursDisplay" style={{left:xoffs}}>
                                  {HM}
                              </div>
                            )
                          }) 
                        }
                        <div id="EpgDotContainer">
                            { channelEPG.map(epg => 
                              <MyEpgDotContainer 
                                  epg={epg} 
                                  startStop={getDayStartStop(_epgFullDate)} 
                                  onClickEvent={() => (epg.START_TIME <= Math.floor(Date.now()/1000)) ? playRewindAbs(epg.START_TIME) : ""}
                              />
                            )}
                        </div>
                        <MyRullerDrawLines/>
                        <MyStreamLineContainer 
                            recordingRanges={recordingRanges}
                            playRewindLine={playRewindLine}
                            onStreamLineHover={onStreamLineHover}
                            streamHoverTimeHide={streamHoverTimeHide}
                            trackerPos={trackerPos}
                        />
                    </div>
                </div>
                <div style={{textAlign:'center'}}>
                    <MyEpgCalendarContainer activeChannel={activeChannel} epgDay={epgDay} getEpgData={getEpgData} />
                </div>
            </div>
        </div>
    </div>
  );
}

export default MyTv;