import React from 'react';
import './MyEpgDotContainer.css'

const MyEpgDotContainer = (props) => {
    const showTitle = (e) => {
        // console.log('eeee', e)
        var offsetx = e.nativeEvent.clientX //e.offsetLeft + 5;
        var streamLineHoverTime = document.getElementsByClassName("streamLineHoverTime")[0];
        var streamLineHoverTimeArrow = document.getElementsByClassName("streamLineHoverTimeArrow")[0];
        var streamLineTitle = document.getElementsByClassName("streamLineTitle")[0];
        streamLineHoverTime.style.display = 'block';
        streamLineHoverTimeArrow.style.display = 'block';
        streamLineTitle.style.display = 'block';
        var title = e.target.title;
        streamLineTitle.innerHTML = title;
        streamLineTitle.style.left = offsetx.toString() + 'px' //e.nativeEvent.clientX
        streamLineHoverTime.style.left = (offsetx - 50).toString() + 'px';
        streamLineHoverTimeArrow.style.left = (offsetx - 50).toString() + 'px';
        const date = new Date(e.target.attributes.sTime.nodeValue*1000);
        // console.log('tmtm= ', e.target.attributes.stime.nodeValue)
        streamLineHoverTime.innerText = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    }
    const hideTitle = () => {
        document.getElementsByClassName("streamLineHoverTime")[0].style.display = 'none';
        document.getElementsByClassName("streamLineHoverTimeArrow")[0].style.display = 'none';
        document.getElementsByClassName("streamLineTitle")[0].style.display = 'none';
    }
    const fullday = 86400 //60*60*24
    const xoffs = (((props.epg.START_TIME - props.startStop[0])/fullday)*100).toString() + '%'
    return (
        <span 
            className='channelDot' 
            onMouseOverCapture={showTitle} 
            onMouseOutCapture={hideTitle} 
            style={{left: xoffs}} 
            title={props.epg.TITLE} 
            sTime={props.epg.START_TIME}
            onClick={props.onClickEvent}
        >
        </span>
    );
};

export default MyEpgDotContainer;