import React from 'react';
import screenfull from 'screenfull';
import MaterialIcon from 'react-google-material-icons';
import './MyPlayerInnerControls.css';

const MyPlayerInnerControls = (props) => {
    const onClickFullScreen = () => {
        //screenfull.request(props.myPlayerRef.current.wrapper)
        //screenfull.isFullscreen ?  : screenfull.request(props.myPlayerF)
        screenfull.toggle(props.myPlayerF)
    }
    return (
        <div id="playerControls" className="PlayerControlls col position-absolute">
            <div className="Group">
                <div className='Icon'>
                    <span onClick={() => props.setMuted(m => !m)}>
                        <MaterialIcon icon={ props.muted ? "volume_off" : (props.volume > 0.5) ? "volume_up" : "volume_down" }/>
                    </span>
                </div>
                <div className='Icon'> {/* or className="VolumenBar" */}
                    <section>
                        <input 
                            className="volumeBar"
                            type="range"
                            min={0}
                            max={1}
                            step={0.02}
                            value={props.volume}
                            onChange={ event => {
                                props.setVolume(event.target.valueAsNumber);
                                (event.target.valueAsNumber === 0) ? props.setMuted(true) : props.setMuted(false);
                            }}
                        />
                    </section>
                </div>
            </div>
            <div className='Icon'>
                <span onClick={onClickFullScreen}>
                    <MaterialIcon icon={screenfull.isFullscreen ? "fullscreen_exit" : "fullscreen"}/>
                </span>
            </div>
        </div>
    );
};

export default MyPlayerInnerControls;