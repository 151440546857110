import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './MyChannelsListContainer.css';

const MyChannelsListContainer = (props) => {
    const channels = props.channels
    const activeChannel = props.activeChannel
    const myRefC = useRef(null)
    // console.log('inner alert');
    return (
        <div className="p0 channel-list" id="ChannelsListContainer">
            <ul className="p0 channelfilter">
                {channels.map(channel => {
                    const ref = (activeChannel === channel.UID) ? myRefC : undefined
                    return (
                    <li 
                        ref={ref}
                        onClick={() => props.setActiveChannel(channel.UID)} 
                        className={(activeChannel === channel.UID) ? 'active__channel' : 'passive__channel'}
                    >
                        <Link to={"/tv/" + channel.UID} style={{ textDecoration: 'none', outline: 'none' }}>
                            <span>
                                <img src={channel.CHANNEL_LOGO} style={{width:28, height:28}} alt={channel.CHANNEL_NAME} title={channel.CHANNEL_NAME} /> { props.hideNames ? '' : channel.CHANNEL_NAME }
                            </span>
                        </Link>
                    </li>
                    )}
                )}
                { (myRefC.current) ? window.scrollTo(0, myRefC.current.scrollIntoViewIfNeeded()) : null }
            </ul>
        </div>
    );
};

export default MyChannelsListContainer;