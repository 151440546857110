import React from 'react';
//import { BiRadio, BiTv, BiWallet, BiWater } from 'react-icons/bi'
import { BrowserRouter, Link } from 'react-router-dom';
import MaterialIcon from 'react-google-material-icons';
import './MyMainMenu.css';

const MyMainMenu = (props) => {
    return (
        <BrowserRouter>
            <ul className="mainMenu">
            <li>
                <Link to="/tv">
                    <div><MaterialIcon icon="live_tv" size={32}/></div>
                    { props.hideNames ? '' : <div>ტელევიზია</div> }
                </Link>
            </li>
            <li>
                <Link to="/radio">
                    <div><MaterialIcon icon="radio" size={32}/></div>
                    { props.hideNames ? '' : <div>რადიო</div> }
                </Link>
            </li>
            <li>
                <Link to="/weather">
                    <div><MaterialIcon icon="thermostat" size={32}/></div>
                    { props.hideNames ? '' : <div>ამინდი</div> }
                </Link>
            </li>
            <li>
                <Link to="/currency">
                    <div><MaterialIcon icon="currency_exchange" size={32}/></div>
                    { props.hideNames ? '' : <div>ვალუტა</div> }
                </Link>
            </li>
        </ul>
      </BrowserRouter>
    );
};

export default MyMainMenu;