import React from 'react';
import myBtnCss from './MyButton.module.css';
// import MaterialIcon from 'react-google-material-icons';
import MyButton from './MyButton';
import { faBackward, faForward, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MyPlayerControls = (props) => {
    const liveStyle = props.isLive ? {fontWeight : 'bold', background : 'cyan'} : {fontWeight : 'normal'}
    return (
        <div className="player__controls">
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(-600)}>
            {/* <MaterialIcon icon="fast_rewind"/> */}
            <FontAwesomeIcon icon={faBackward}/> 10 min
        </MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(-300)}>
            <FontAwesomeIcon icon={faBackward}/> 5 min
        </MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(-60)}>
            <FontAwesomeIcon icon={faBackward}/> 1 min
        </MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(-30)}>
            <FontAwesomeIcon icon={faBackward}/> 30 sec</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(-10)}>
            <FontAwesomeIcon icon={faBackward}/> 10 sec</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnPlayPause} onClick={props.PlayPause}>
          <FontAwesomeIcon id="btnPlayPause" icon={props.playing ? faPause : faPlay}/>
        </MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(10)} disabled={ props.isLive ? "Disabled" : ""}>
            <FontAwesomeIcon icon={faForward}/> 10 sec</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(30)} disabled={ props.isLive ? "Disabled" : ""}>
            <FontAwesomeIcon icon={faForward}/> 30 sec</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(60)} disabled={ props.isLive ? "Disabled" : ""}>
            <FontAwesomeIcon icon={faForward}/> 1 min</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(300)} disabled={ props.isLive ? "Disabled" : ""}>
            <FontAwesomeIcon icon={faForward}/> 5 min</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playRewind(600)} disabled={ props.isLive ? "Disabled" : ""}>
            <FontAwesomeIcon icon={faForward}/> 10 min</MyButton> &nbsp;&nbsp;
        <MyButton className={myBtnCss.myBtnRewind} onClick={() => props.playLive()} disabled={ props.isLive ? "Disabled" : ""} style={liveStyle}> Live</MyButton> &nbsp;&nbsp;
      </div>
);
};

export default MyPlayerControls;
