// import { ContentCopy } from '@mui/icons-material';
import React from 'react';

const MyRullerDrawLines = () => {
  var lines =[]
  const yloop = [75, 50, 75, 0]
  for (var i=0; i<24; i++) {
    yloop.map((ypos, index) => {
      var pos = i*4 + index;
      var xoffs = ((pos+1)*100/96).toString() + '%';
      lines[pos] = <line x1={xoffs} x2={xoffs} y1="100%" y2={ypos.toString() + '%'} stroke="#CCC" fill="transparent" stroke-width="0.8"></line>
    })
  }
  return (
    <svg id="timlineSVG">
      <line x1="0" x2="0" y1="100%" y2="0%" stroke="#CCC" fill="transparent" stroke-width="0.8"></line>
      {lines}
    </svg>
  )
};

export default MyRullerDrawLines;