import React from 'react';
import './MyStreamLineContainer.css';

const MyStreamLineContainer = (props) => {
    const recordingRanges = props.recordingRanges;
    return (
        <div id="streamLine" className="streamLine">
            <div 
                id="elapsedTimeBar" 
                class="streamLineBlue" 
                onClick={props.playRewindLine}
                onMouseMoveCapture={props.onStreamLineHover} 
                onMouseOut={props.streamHoverTimeHide} 
            >&nbsp;
            </div>
            <div className="streamLineRecOK" id="streamLineRecOK" style={{width: '100%'}}>
                { recordingRanges.map(recordRange => {
                    // <MyRecordLine
                    //     pos={props.trackerPos(recordRange.from)} 
                    //     duration={recordRange.duration} 
                    // />
                    const pos = props.trackerPos(recordRange.from).toString() + '%'
                    const pwidth = (recordRange.duration/864).toString() + '%'
                    return (
                        <div className='streamLineRecOKLL' style={{left:pos, width:pwidth}}>
                            &nbsp;
                        </div>
                    )}
                )}
            </div>
            <div>
                <div className="streamLineCurrentTime" style={{display:'none'}}></div>
                <div className="streamLineCurrentTimeArrow" style={{display:'none'}}></div>
            </div>
            <div>
                <div className="streamLineTitle" style={{display:'none'}}></div>
                <div className="streamLineHoverTime" style={{display:'none'}}></div>
                <div className="streamLineHoverTimeArrow" style={{display:'none'}}></div>
            </div>
        </div>
    );
};

export default MyStreamLineContainer;