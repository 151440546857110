import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import './MyEpgListContainer.css';

const MyEpgListContainer = (props) => {
    const channelEPG = props.channelEPG
    const activeChannel = props.activeChannel
    const videoTimeStamp = props.videoTimeStamp
    const myRefE = useRef(null)
    return (
        <div id="EpgListContainer">
            <ul className="epgfilter">
                {channelEPG.map(epg => {
                    const dateTime = SimpleDateTime({'timeSeparator': ':', 'showTime':'1', 'showDate':'0', 'children': epg.START_TIME});
                    const renderHTML = (rawHTML) => React.createElement("span", { className:"text", dangerouslySetInnerHTML: { __html: rawHTML } });
                    var cls = (videoTimeStamp >= epg.START_TIME && videoTimeStamp < epg.END_TIME) ? 'active__epg' : 'passed__epg';
                    if (epg.START_TIME > Math.floor(Date.now()/1000)) cls = 'next__epg';
                    const clsli = (cls === 'active__epg') ? 'active__epgli' : 'passed__epg';
                    const ref = (cls === 'active__epg') ? myRefE : undefined
                    return(
                        <li 
                            ref={ref}
                            className={clsli}
                            onClick={() => (epg.START_TIME <= Math.floor(Date.now()/1000)) ? props.playRewindAbs(epg.START_TIME) : ""}
                        >
                            <Link to={"/tv/" + activeChannel + "/" + epg.START_TIME}>
                                <div className={cls}>
                                    <div class='time'>{dateTime.substring(0,5)}</div>
                                    <div>{renderHTML(epg.TITLE)}</div>
                                </div>
                            </Link>
                        </li>
                    )}
                )}
            </ul>
            { (myRefE.current) ? window.scrollTo(0, myRefE.current.scrollIntoViewIfNeeded()) : null }
        </div>
    );
};

export default MyEpgListContainer;